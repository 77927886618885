<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>{{ categoryTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <!-- <v-container v-if="!loadPage"> -->
    <v-container>
      <v-row align="center">
        <no-content v-if="posts.length == 0" route-name title="Записей нет"></no-content>
        <post-card v-for="(item, index) in posts" :key="item.id" :item="item" :index="index"></post-card>
      </v-row>
      <v-row align="center">
        <v-col cols="12">
          <v-pagination
            v-if="pages > 1"
            @input="goPage"
            v-model="page"
            :length="pages"
            :total-visible="7"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PostCard from "./PostCard.vue";

export default {
  components: {
    PostCard,
  },
  data: () => ({
    categorySlug: "",
    page: 1,
    pages: 0,
    loading: false,
  }),
  methods: {
    getPosts() {
      var app = this;

      this.$store
        .dispatch("info/getPosts", {
          categorySlug: app.categorySlug,
          page: app.page,
        })
        .then((response) => {
          // app.loading = false;
          if (response.data.success == 1) {
            app.pages = response.data.posts.last_page;
          }
        });
    },
    goPage(nextPage) {
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          params: { slug: this.categorySlug },
          query: { page: nextPage },
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      posts: "info/posts",
      category: "info/category",
    }),
    categoryTitle() {
      if (this.category) {
        return this.category.name;
      }
      return "";
    },
  },
  created() {
    //console.log(this.$route.query.page);
    this.categorySlug = this.$route.params.slug
      ? this.$route.params.slug
      : "all";
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.getPosts();
  },
};
</script>
