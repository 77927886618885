<template>
  <v-col class="text-left" cols="12">
    <v-card class="post-card">
      <div class="book-long d-flex flex-no-wrap justify-start">
        <div class="book-image" :to="{name: 'InfoShow', params: {slug: item.slug}}">
          <router-link :to="{name: 'InfoShow', params: {slug: item.slug}}">
            <img v-if="item.card_image" :src="item.card_image" />
          </router-link>
        </div>
        <div class="book-long-body">
          <!-- <div class="book-title" ><a :to="{name: 'InfoShow', params: {slug: item.slug}}">{{ item.title }}</a></div> -->
          <div class="book-title" :class="$vuetify.breakpoint.xsOnly ? 'mobile' : ''">
            <router-link :to="{name: 'InfoShow', params: {slug: item.slug}}">{{ item.title }}</router-link>
          </div>

          <div
            v-if="$vuetify.breakpoint.smAndUp && item.substr_excerpt"
            class="book-text"
          >{{ item.substr_excerpt }}</div>

          <v-card-actions class="mt-auto">
            <v-spacer></v-spacer>

            <v-btn :to="{name: 'InfoShow', params: {slug: item.slug}}" text tile>Подробнее</v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["item", "index"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
